<template>
  <div class="main_container">
    <div class="main_page">
      <div class="page_title">{{ $route.meta.title }}</div>
      <div class="page_containt">
        <div class="page_box" ref="pageBox">
          <el-table
            v-loading="loading"
            row-key="id"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="listData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column label="小程序码" width="110">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.qr"
                  :preview-src-list="[scope.row.qr]"
                  fit="contain"
                  v-if="scope.row.qr"
                ></el-image>
                <el-image
                  style="width: 100px; height: 100px"
                  src="/images/image-empty.png"
                  fit="contain"
                  v-else
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="直播间头像" width="110">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.web_vip_face"
                  fit="contain"
                  v-if="scope.row.web_face_url"
                ></el-image>
                <el-image
                  style="width: 100px; height: 100px"
                  src="/images/image-empty.png"
                  fit="contain"
                  v-else
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="直播间封面" width="110">
              <template slot-scope="scope">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.web_face_url"
                  fit="contain"
                  v-if="scope.row.web_face_url"
                ></el-image>
                <el-image
                  style="width: 100px; height: 100px"
                  src="/images/image-empty.png"
                  fit="contain"
                  v-else
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column prop="title" label="直播间名称" min-width="300">
            </el-table-column>
            <el-table-column label="是否私密" width="80">
              <template slot-scope="scope">
                {{ scope.row.is_privacy == 1 ? "是" : "否" }}
              </template>
            </el-table-column>
            <el-table-column label="主播" width="60">
              <template slot-scope="scope">
                <el-avatar
                  v-if="scope.row.vip"
                  :src="scope.row.vip.face_url"
                  :size="40"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column label="">
              <template slot-scope="scope">
                {{ scope.row.vip?scope.row.vip.name:'' }}
              </template>
            </el-table-column>
            <el-table-column prop="man_num" label="初始人数" width="100">
            </el-table-column>
            <el-table-column label="人数实时倍率" width="150">
              <template slot-scope="scope">
                {{ scope.row.min_num }}至{{ scope.row.max_num }}
              </template>
            </el-table-column>
            <el-table-column label="直播状态" width="100">
              <template slot-scope="scope">
                <div v-if="scope.row.status == 1">直播中</div>
                <div v-if="scope.row.status == 2">未直播</div>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="260"
            >
              <template slot-scope="scope">
                <el-button
                  @click="showWordsForm(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                >
                  快捷语
                </el-button>
                <el-button
                  @click="showAdminForm(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-user"
                  size="small"
                >
                  助理
                </el-button>
                <el-button
                  @click="showBlackList(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-user"
                  size="small"
                >
                  黑名单
                </el-button>
                <el-button
                  @click="doForm(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                >
                  编辑
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination
              background
              layout="total,prev,pager,next,jumper"
              @current-change="handleCurrentChange"
              :total="pageConfig.counts"
              :page-size="pageConfig.pageSize"
              :current-page="pageConfig.pageIndex"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      title="直播间编辑"
      size="80%"
      :visible.sync="drawer"
      direction="rtl"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <div class="drawer_box">
        <el-form
          ref="modelForm"
          :rules="modelRules"
          :model="modelForm"
          label-width="150px"
          size="small"
        >
          <el-form-item label="直播间名称" size="small" prop="title">
            <el-input
              v-model="modelForm.title"
              style="width: 300px"
              placeholder="直播间名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="直播间头像" size="small" prop="vip_face">
            <el-button
              size="small"
              style="width: 100px"
              @click="getResources('vip_face')"
              >+选择图片</el-button
            >
          </el-form-item>
          <el-form-item
            label=""
            size="small"
            prop="web_vip_face"
            class="no-bottom"
          >
            <div class="more_img_box">
              <div class="img_item one_img_item">
                <el-image
                  class="img"
                  :src="
                    modelForm.web_vip_face
                      ? modelForm.web_vip_face
                      : '/images/image-empty.png'
                  "
                  fit="contain"
                ></el-image>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            图片建议大小：200px*200px
          </el-form-item>
          <el-form-item label="直播间封面" size="small" prop="face_url">
            <el-button
              size="small"
              style="width: 100px"
              @click="getResources('face_url')"
              >+选择图片</el-button
            >
          </el-form-item>
          <el-form-item
            label=""
            size="small"
            prop="web_face_url"
            class="no-bottom"
          >
            <div class="more_img_box">
              <div class="img_item one_img_item">
                <el-image
                  class="img"
                  :src="
                    modelForm.web_face_url
                      ? modelForm.web_face_url
                      : '/images/image-empty.png'
                  "
                  fit="contain"
                ></el-image>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            图片建议大小：500px*400px
          </el-form-item>
          <el-form-item label="主播" size="small" prop="vip_id">
            <el-button
              size="small"
              style="width: 100px"
              @click="selectVipWin('room')"
              >+选择主播</el-button
            >
          </el-form-item>
          <el-form-item label="" size="small" v-if="roomVip != null">
            <el-row type="flex" align="middle">
              <el-col style="width: auto">
                <el-avatar :src="roomVip.face_url" :size="40"></el-avatar>
              </el-col>
              <el-col style="width: auto; padding-left: 10px">
                {{ roomVip.name }}
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            label="是否私密"
            size="small"
            prop="man_num"
            class="no-bottom"
          >
            <el-select
              v-model="modelForm.is_privacy"
              size="small"
              popper-class="select"
              style="width: 80px"
              placeholder=""
            >
              <el-option
                v-for="item in privacyList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            私密直播时直播码或分享单次有效
          </el-form-item>
          
          <el-form-item
            label="初始化人数"
            size="small"
            prop="man_num"
            class="no-bottom"
          >
            <el-input
              v-model="modelForm.man_num"
              placeholder="初始化人数"
            ></el-input>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            直播开始时默认显示人数
          </el-form-item>
          <el-form-item
            label="人数实时倍率"
            size="small"
            prop="min_num"
            class="no-bottom"
          >
            <el-input
              v-model="modelForm.min_num"
              placeholder=""
              style="width: 50px"
            ></el-input>
            至
            <el-input
              v-model="modelForm.max_num"
              style="width: 50px"
              placeholder=""
            ></el-input>
          </el-form-item>
          <el-form-item label="" size="small" class="msg">
            进播人数实时倍率
          </el-form-item>
          <el-form-item label="直播间描述" size="small" prop="msg">
            <el-input
              v-model="modelForm.msg"
              style="width: 400px"
              placeholder="直播间描述"
            ></el-input>
          </el-form-item>
          <el-form-item label="直播间公告" size="small" prop="notice">
            <el-input
              v-model="modelForm.notice"
              style="width: 400px"
              placeholder="直播间公告"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="是否开启欢迎提示"
            size="small"
            prop="is_welcome"
          >
            <el-select
              v-model="modelForm.is_welcome"
              size="small"
              popper-class="select"
              style="width: 80px"
              placeholder=""
            >
              <el-option
                v-for="item in welcomeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="聊天区是否客户分组"
            size="small"
            prop="show_group"
          >
            <el-select
              v-model="modelForm.show_group"
              size="small"
              popper-class="select"
              style="width: 80px"
              placeholder=""
            >
              <el-option
                v-for="item in showGroupList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="聊天区是否客户标签"
            size="small"
            prop="show_label"
          >
            <el-select
              v-model="modelForm.show_label"
              size="small"
              popper-class="select"
              style="width: 80px"
              placeholder=""
            >
              <el-option
                v-for="item in showLabelList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="聊天区隐藏客户姓名"
            size="small"
            prop="show_label"
          >
            <el-select
              v-model="modelForm.is_star"
              size="small"
              popper-class="select"
              style="width: 80px"
              placeholder=""
            >
              <el-option
                v-for="item in showStarList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <div class="drawer_footer" style="left: 20%">
            <el-button
              type="primary"
              size="small"
              native-type="submit"
              @click.native.prevent="handleSubmit"
              :loading="btnLogining"
              >提交</el-button
            >
            <el-button size="small" @click="drawer = false">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-drawer>
    <el-drawer
      title="直播间助理"
      size="80%"
      :visible.sync="adminDrawer"
      direction="rtl"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <div class="drawer_box">
        <div class="page_search">
          <el-row type="flex">
            <el-col class="search_col">
              <el-button
                type="primary"
                size="small"
                @click="selectVipWin('admin')"
                :loading="btnLogining"
                >新增助理</el-button
              >
            </el-col>
          </el-row>
        </div>
        <div class="page_box">
          <el-table
            v-loading="adminLoading"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="adminList"
            style="width: 100%"
          >
            <el-table-column label="助理">
              <template slot-scope="scope">
                <el-row type="flex" align="middle">
                  <el-col style="width: auto">
                    <el-avatar :src="scope.row.face_url" :size="40"></el-avatar>
                  </el-col>
                  <el-col style="width: auto; padding-left: 10px">
                    {{ scope.row.name }}
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="80"
            >
              <template slot-scope="scope">
                <el-button
                  @click="deleteAdmin(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
    <el-drawer
      title="快捷语列表"
      size="80%"
      :visible.sync="wordsDrawer"
      direction="rtl"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <div class="drawer_box">
        <div class="page_search">
          <el-row type="flex">
            <el-col class="search_col">
              <el-button type="primary" size="small" @click="doWordsForm(null)"
                >新增快捷语</el-button
              >
            </el-col>
          </el-row>
        </div>
        <div class="page_box">
          <el-table
            v-loading="wordsLoading"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="wordsList"
            style="width: 100%"
          >
            <el-table-column label="内容" prop="words"> </el-table-column>
            <el-table-column prop="created_at" label="创建日期" width="140">
            </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="130"
            >
              <template slot-scope="scope">
                <el-button
                  class="btn"
                  type="text"
                  icon="el-icon-edit"
                  size="small"
                  @click="doWordsForm(scope.row)"
                >
                  编辑
                </el-button>
                <el-button
                  @click="deleteWordsItem(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>
    <el-drawer
      title="黑名单列表"
      size="80%"
      :visible.sync="blackDrawer"
      direction="rtl"
      :wrapperClosable="false"
      :close-on-press-escape="false"
    >
      <div class="drawer_box">
        <div class="page_search">
          <el-form ref="blackSearchForm" :model="blackSearchForm">
            <el-row type="flex">
              <el-col class="search_col">
                <el-form-item size="small" prop="key">
                  <el-input
                    size="small"
                    type="text"
                    v-model="blackSearchForm.key"
                    style="width: 180px"
                    placeholder="名称"
                  />
                </el-form-item>
              </el-col>
              <el-col class="search_col">
                <el-button
                  type="primary"
                  size="small"
                  native-type="submit"
                  @click.native.prevent="handleBlackSearch"
                  >搜索</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="page_box">
          <el-table
            v-loading="loading"
            size="small"
            class="el_table"
            cell-class-name="el_table_cell"
            header-row-class-name="el_table_header"
            :data="blackListData"
            :height="tableHeight"
            style="width: 100%"
          >
            <el-table-column prop="vip_no" label="ID" width="120">
            </el-table-column>
            <el-table-column label="头像" width="60">
              <template slot-scope="scope">
                <el-avatar
                  :src="scope.row.face_url"
                  v-if="scope.row.face_url"
                  :size="50"
                ></el-avatar>
                <el-avatar
                  icon="el-icon-user-solid"
                  v-else
                  :size="50"
                ></el-avatar>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="姓名"> </el-table-column>
            <el-table-column
              fixed="right"
              align="right"
              header-align="right"
              label="操作"
              width="120"
            >
              <template slot-scope="scope">
                <el-button
                  @click="blackStatus(scope.row)"
                  class="btn"
                  type="text"
                  icon="el-icon-delete"
                  size="small"
                >
                  解除黑名单
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="page_pagination">
            <el-pagination
              background
              layout="total,prev,pager,next,jumper"
              @current-change="handleBlackCurrentChange"
              :total="blackPageConfig.counts"
              :page-size="blackPageConfig.pageSize"
              :current-page="blackPageConfig.pageIndex"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </el-drawer>
    <el-dialog
      :title="dialogWordsTitle"
      :visible.sync="dialogWordsVisible"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      @close="dialogWordsVisible = false"
      width="400px"
    >
      <el-form
        ref="wordsModelForm"
        :model="wordsModelForm"
        :rules="wordsModelRules"
        label-width="100px"
        size="small"
        class="form_box"
      >
        <el-form-item label="内容：" class="el-form-item-no" prop="words">
          <el-input
            style="width: 200px"
            size="small"
            v-model.trim="wordsModelForm.words"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button
            type="primary"
            native-type="submit"
            style="width: 80px"
            @click.native.prevent="handleWordsSubmit"
            size="small"
            :loading="btnWordsLogining"
            >确定</el-button
          >
          <el-button style="width: 80px" @click="dialogWordsVisible = false"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <ResourceWin
      ref="ResourceWin"
      v-if="reVisible"
      :resType="1"
      :isMore="false"
      @closeResourceWin="reVisible = false"
      @setResources="setResources"
    />
    <VipWin
      ref="VipWin"
      v-if="vipVisible"
      @closeWin="vipVisible = false"
      @setVips="setVips"
    />
  </div>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import roomApi from "@/api/room";
import ResourceWin from "@/components/resource/win";
import VipWin from "@/components/vip/win";
export default {
  components: {
    ResourceWin,
    VipWin,
  },
  data() {
    return {
      loading: false,
      btnLogining: false,
      btnWordsLogining: false,
      adminLoading: false,
      wordsLoading: false,
      drawer: false,
      adminDrawer: false,
      wordsDrawer: false,
      blackDrawer: false,
      dialogWordsVisible: false,
      searchForm: {
        key: "",
      },
      model: null,
      listData: [],
      pageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
      roomVip: null,
      privacyList: [
        {
          id: 0,
          name: "否",
        },
        {
          id: 1,
          name: "是",
        },
      ],
      welcomeList: [
        {
          id: 0,
          name: "否",
        },
        {
          id: 1,
          name: "是",
        },
      ],
      showGroupList: [
        {
          id: 0,
          name: "否",
        },
        {
          id: 1,
          name: "是",
        },
      ],
      showLabelList: [
        {
          id: 0,
          name: "否",
        },
        {
          id: 1,
          name: "是",
        },
      ],
      showStarList: [
        {
          id: 0,
          name: "关闭",
        },
        {
          id: 1,
          name: "开启",
        },
      ],
      modelForm: {
        vip_face: "",
        web_vip_face: "",
        face_url: "",
        web_face_url: "",
        title: "",
        msg: "",
        man_num: 10000,
        min_num: 5,
        max_num: 10,
        vip_id: null,
        is_privacy: 0,
        is_welcome: 0,
        is_star:0,
        show_group:1,
        show_label:0,
        notice: "",
      },
      modelRules: {
        title: [
          { required: true, message: "请输入", trigger: "blur" },
          { min: 1, max: 100, message: "长度不超过100个字符" },
        ],
        vip_face: [{ required: true, message: "请选择", trigger: "blur" }],
        face_url: [{ required: true, message: "请选择", trigger: "blur" }],
        msg: [{ min: 0, max: 100, message: "长度不超过100个字符" }],
        man_num: [{ required: true, message: "请输入", trigger: "blur" }],
        min_num: [{ required: true, message: "请输入", trigger: "blur" }],
        max_num: [{ required: true, message: "请输入", trigger: "blur" }],
        vip_id: [{ required: true, message: "请选择", trigger: "blur" }],
        notice: [{ min: 0, max: 100, message: "长度不超过100个字符" }],
      },
      adminList: [],
      reVisible: false,
      vipVisible: false,
      tableHeight: 500,
      dialogWordsTitle: "",
      wordsList: [],
      wordsModel: null,
      wordsModelForm: {
        words: "",
      },
      wordsModelRules: {
        words: [
          { required: true, message: "请输入内容", trigger: "blur" },
          { min: 1, max: 50, message: "长度不超过50个字符" },
        ],
      },
      blackSearchForm: {
        room_id: 0,
        key: "",
      },
      blackListData: [],
      blackPageConfig: { pageSize: 50, pageIndex: 1, counts: 0 },
    };
  },
  _relType: "",
  _vipType: "",
  created() {},
  mounted() {
    onTableHeight(this);
    this.getList();
  },
  methods: {
    //列表
    getList(pageIndex = 1) {
      this.loading = true;
      roomApi
        .roomList({
          ...this.searchForm,
          page_size: this.pageConfig.pageSize,
          page_index: pageIndex,
        })
        .then((res) => {
          this.listData = res.list;
          this.pageConfig.pageIndex = res.page.page_index;
          this.pageConfig.counts = res.page.counts;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    handleCurrentChange(val) {
      this.getList(val);
    },
    selectVipWin(type) {
      this._vipType = type;
      this.vipVisible = true;
    },
    setVips(data) {
      if (data.list.length > 0) {
        if (this._vipType == "room") {
          this.roomVip = data.list[0];
          this.modelForm.vip_id = this.roomVip.id;
          this.vipVisible = false;
        } else if (this._vipType == "admin") {
          this.addAdmin(data.list[0].id);
          this.vipVisible = false;
        }
      }
    },
    addAdmin(vipID) {
      this.btnLogining = true;
      roomApi
        .roomAdminForm({
          id: this.model ? this.model.id : 0,
          vip_id: vipID,
        })
        .then((res) => {
          this.$message({
            message: "提交成功",
            type: "success",
            offset: 60,
          });
          this.getAdminList();
        })
        .catch(() => {})
        .finally(() => {
          this.btnLogining = false;
        });
    },
    //删除
    deleteAdmin(row) {
      this.$confirm("确定是否删除该助理?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          roomApi
            .roomAdminDelete({
              id: this.model.id,
              vip_id: row.id,
            })
            .then((res) => {
              message.close();
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getAdminList();
            })
            .catch((res) => {
              message.close();
            });
        })
        .catch(() => {});
    },
    getResources(type) {
      this._relType = type;
      this.reVisible = true;
    },
    //设置资源回调方法
    setResources(data) {
      if (this._relType == "vip_face") {
        this.modelForm.vip_face = data.files[0].path;
        this.modelForm.web_vip_face = data.files[0].web_path;
      } else if (this._relType == "face_url") {
        this.modelForm.face_url = data.files[0].path;
        this.modelForm.web_face_url = data.files[0].web_path;
      }
      this.reVisible = false;
    },
    doForm(row) {
      this.model = row;
      this.$nextTick(() => {
        if (this.model != null) {
          this.modelForm.title = this.model.title;
          this.modelForm.vip_face = this.model.vip_face;
          this.modelForm.web_vip_face = this.model.web_vip_face;
          this.modelForm.face_url = this.model.face_url;
          this.modelForm.web_face_url = this.model.web_face_url;
          this.modelForm.msg = this.model.msg;
          this.modelForm.man_num = this.model.man_num;
          this.modelForm.min_num = this.model.min_num;
          this.modelForm.max_num = this.model.max_num;
          this.modelForm.vip_id = this.model.vip_id;
          this.modelForm.notice = this.model.notice;
          this.modelForm.is_privacy = this.model.is_privacy;
          this.modelForm.is_welcome=this.model.is_welcome;
          this.modelForm.is_star=this.model.is_star;
          this.modelForm.show_group=this.model.show_group;
          this.modelForm.show_label=this.model.show_label;
          this.roomVip = this.model.vip;
        }
      });
      this.drawer = true;
    },
    handleSubmit() {
      this.$refs.modelForm.validate((valid) => {
        if (valid) {
          this.btnLogining = true;
          roomApi
            .roomForm({
              id: this.model ? this.model.id : 0,
              ...this.modelForm,
            })
            .then((res) => {
              this.drawer = false;
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.getList(1);
            })
            .catch(() => {})
            .finally(() => {
              this.btnLogining = false;
            });
        }
      });
    },
    showAdminForm(row) {
      this.adminDrawer = true;
      this.model = row;
      this.getAdminList();
    },
    getAdminList() {
      this.adminLoading = true;
      roomApi
        .roomAdminList({
          room_id: this.model.id,
        })
        .then((res) => {
          this.adminList = res.list;
        })
        .catch(() => {})
        .finally(() => {
          this.adminLoading = false;
        });
    },
    showWordsForm(row) {
      this.wordsDrawer = true;
      this.model = row;
      this.getWordsList();
    },
    getWordsList() {
      this.wordsLoading = true;
      roomApi
        .roomWordsList({
          room_id: this.model.id,
        })
        .then((res) => {
          this.wordsList = res.list;
        })
        .catch(() => {})
        .finally(() => {
          this.wordsLoading = false;
        });
    },
    deleteWordsItem(row) {
      this.$confirm("确定是否删除该信息吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          roomApi
            .roomWordsDelete({ id: row.id })
            .then((res) => {
              this.$message({
                message: "删除成功",
                type: "success",
                offset: 60,
              });
              this.getWordsList();
            })
            .catch((res) => {})
            .finally(() => {
              message.close();
            });
        })
        .catch(() => {});
    },
    //表单
    doWordsForm(row) {
      this.wordsModel = row;
      this.dialogWordsTitle =
        this.wordsModel == null ? "新增快捷语" : "编辑快捷语";
      this.$refs.wordsModelForm
        ? this.$refs.wordsModelForm.resetFields()
        : null;
      this.$nextTick(() => {
        if (this.wordsModel != null) {
          this.wordsModelForm.words = this.wordsModel.words;
        }
      });
      this.dialogWordsVisible = true;
    },
    //提交
    handleWordsSubmit() {
      this.$refs.wordsModelForm.validate((valid) => {
        if (valid) {
          this.wordsLoading = true;
          roomApi
            .roomWordsForm({
              id: this.wordsModel != null ? this.wordsModel.id : 0,
              room_id: this.model.id,
              ...this.wordsModelForm,
            })
            .then((res) => {
              this.$message({
                message: "提交成功",
                type: "success",
                offset: 60,
              });
              this.dialogWordsVisible = false;
              this.getWordsList();
            })
            .catch((res) => {})
            .finally(() => {
              this.wordsLoading = false;
            });
        }
      });
    },
    blackStatus(row) {
      this.$confirm("确定是否解除该黑名单吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let message = this.$message({
            message: "请求中",
            iconClass: "el-icon-loading",
            customClass: "message-loading",
            duration: 0,
            offset: 60,
          });
          roomApi
            .roomBlackStatus({
              room_id: this.blackSearchForm.room_id,
              vip_id: row.vip_id,
            })
            .then((res) => {
              this.$message({
                message: "解除成功",
                type: "success",
                offset: 60,
              });
              this.getBlackList();
            })
            .catch((res) => {})
            .finally(() => {
              message.close();
            });
        })
        .catch(() => {});
    },
    handleBlackSearch() {
      this.getBlackList();
    },
    handleBlackCurrentChange(val) {
      this.getBlackList(val);
    },
    showBlackList(row) {
      this.blackSearchForm = {
        room_id: row.id,
        key: "",
      };
      this.blackDrawer = true;
      this.getBlackList();
    },
    getBlackList(pageIndex = 1) {
      this.loading = true;
      roomApi
        .roomBlackList({
          ...this.blackSearchForm,
          page_size: this.blackPageConfig.pageSize,
          page_index: pageIndex,
        })
        .then((res) => {
          this.blackListData = res.list;
          this.blackPageConfig.pageIndex = res.page.page_index;
          this.blackPageConfig.counts = res.page.counts;
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scope></style>
