<template>
    <el-dialog title="选择用户" :visible.sync="visible" @close="winClose" top="10px" width="840px"
        :close-on-press-escape="false" :close-on-click-modal="false">
        <div class="page_search">
            <el-form ref="searchForm" :model="searchForm">
                <el-row type="flex">
                    <el-col class="search_col">
                        <el-form-item size="small" prop="key">
                            <el-input size="small" type="text" v-model="searchForm.key" style="width: 180px"
                                placeholder="ID/姓名" />
                        </el-form-item>
                    </el-col>
                    <el-col class="search_col">
                        <el-button type="primary" size="small" native-type="submit"
                            @click.native.prevent="handleSearch">搜索</el-button>
                    </el-col>
                    <el-col class="search_col">
                        <el-button size="small" @click="resetSearchForm">重置</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="page_box" ref="pageBox">
            <el-table v-loading="loading" size="small" class="el_table" cell-class-name="el_table_cell"
                header-row-class-name="el_table_header" :data="listData" :height="tableHeight" style="width: 100%">
                <el-table-column prop="vip_no" label="ID" width="120">
                </el-table-column>
                <el-table-column label="头像" width="60">
                    <template slot-scope="scope">
                        <el-avatar :src="scope.row.face_url" v-if="scope.row.face_url" :size="50"></el-avatar>
                        <el-avatar icon="el-icon-user-solid" v-else :size="50"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名">
                </el-table-column>
                <el-table-column label="标签">
                    <template slot-scope="scope">
                        <el-tag type="info" v-for="(item, index) in scope.row.label" :key="index">{{ item }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="创建日期" width="140">
                </el-table-column>
                <el-table-column fixed="right" align="right" header-align="right" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button @click="selectVip(scope.row)" class="btn" type="text" icon="el-icon-check" size="small">
                            选择
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page_pagination">
                <el-pagination background layout="total,prev,pager,next,jumper" @current-change="handleCurrentChange"
                    :total="pageConfig.counts" :page-size="pageConfig.pageSize" :current-page="pageConfig.pageIndex">
                </el-pagination>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { onTableHeight } from "@/lib/table";
import vipApi from "@/api/vip";
export default {
    name: "VipWin",
    data() {
        return {
            visible: true,
            loading: false,
            searchForm: {
                key: "",
                begin_at: "",
                end_at: "",
            },
            listData: [],
            pageConfig: { pageSize: 20, pageIndex: 1, counts: 0 },
            tableHeight: 500,
        };
    },
    mounted() {
        onTableHeight(this);
        this.getList();
    },
    methods: {
        //关闭
        winClose() {
            this.$emit("closeWin", {});
        },
        //查询
        handleSearch() {
            this.getList(1);
        },
        //重置
        resetSearchForm() {
            if (this.$refs.searchForm) this.$refs.searchForm.resetFields();
            this.getList(1);
        },
        //分页
        handleCurrentChange(val) {
            this.getList(val);
        },
        //列表
        getList(pageIndex = 1) {
            this.loading = true;
            vipApi
                .vipList({
                    page_size: this.pageConfig.pageSize,
                    page_index: pageIndex,
                    ...this.searchForm,
                })
                .then((res) => {
                    this.listData = res.list;
                    this.pageConfig.pageIndex = res.page.page_index;
                    this.pageConfig.counts = res.page.counts;
                })
                .catch((res) => { })
                .finally(() => {
                    this.loading = false;
                });
        },
        selectVip(row) {
            let list = [];
            list.push(row);
            this.$emit("setVips", { list: list });
        }
    },
};
</script>
<style scoped></style>
  